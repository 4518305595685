<template>
  <div class="mt-10 mb-10">
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-tabs v-model="tab">
        <v-tab class="green--text">الشهادات الجارية</v-tab>
        <v-tab class="red--text">الشهادات المنتهية</v-tab>
        <v-tab class="gray--text">الشهادات الملغية</v-tab>
      </v-tabs>


      <v-divider></v-divider>
      <v-tabs-items touchless v-model="tab">
        <v-tab-item>
          <v-data-table
            :footer-props="$store.state.footer_props"
            :mobile-breakpoint="0"
            :headers="available_headers"
            :items="certificates.available"
          >
            <template v-slot:[`item.number`]="{ item }">
              {{ item.number }}#
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
            </template>
            <template v-slot:[`item.percent`]="{ item }">
              {{ item.percent }}%
            </template>

            <template v-slot:[`item.total_profit`]="{ item }">
              {{ (item.percent / 100) * item.amount }} 
            </template>
            <template v-slot:[`item.month_profit`]="{ item }">
              {{
                Intl.NumberFormat("en-US").format(
                  ((item.percent / 100) * item.amount) /
                    item.profit_months.toFixed(2)
                )
              }}
            </template>

            <template v-slot:[`item.remining_month`]="{ item }">
              {{ item.profit_months - item.profit_count }} شهر
            </template>
          </v-data-table>
        </v-tab-item>
        <!--/ still working    -->
        <v-tab-item>
          <v-data-table
            :mobile-breakpoint="0"
            :items="certificates.expired"
            :headers="expired_headers"
            :footer-props="$store.state.footer_props"
          >
            <template v-slot:[`item.number`]="{ item }">
              {{ item.number }}#
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
            </template>
            <template v-slot:[`item.percent`]="{ item }">
              {{ item.percent }}%
            </template>

            <template v-slot:[`item.total_profit`]="{ item }">
              {{ (item.percent / 100) * item.amount }} 
            </template>
            <template v-slot:[`item.month_profit`]="{ item }">
              {{
                Intl.NumberFormat("en-US").format(
                  ((item.percent / 100) * item.amount) /
                    item.profit_months.toFixed(2)
                )
              }}
            </template>
          </v-data-table>
        </v-tab-item>
        <!--/ finished   -->
        <v-tab-item>
          <v-data-table
            :mobile-breakpoint="0"
            :items="certificates.canceld"
            :headers="canceld_headers"
            :footer-props="$store.state.footer_props"
          >
            <template v-slot:[`item.number`]="{ item }">
              {{ item.number }}#
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
            </template>
            <template v-slot:[`item.percent`]="{ item }">
              {{ item.percent }}%
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click="open_dialog(item.number, item.cancel_reason)"
                color="blue-grey darken-2"
                class="white--text"
                small
              >
                عرض سبب الألغاء
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <!--/ canceld -->
      </v-tabs-items>
    </v-card>

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title> سبب الغاء الشهادة (#{{ number }}) </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <p>
            {{ reason }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false" text color="success">تم</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: ["certificates", "data_loaded"],
  data() {
    return {
      itemsPerPage: 3,
      itemsPerPageArray: [10, 15, 20],
      number: 0,
      reason: "",
      tab: 0,
      dialog: false,
      dialog_delete: false,
      available_headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنشاء",
          align: "center",
        },
        {
          value: "end_date",
          text: "تاريخ الأنتهاء",
          align: "center",
        },
        {
          value: "amount",
          text: "مبلغ الأستثمار",
          align: "center",
        },
        {
          value: "percent",
          text: "النسبة",
          align: "center",
        },
        {
          value: "month_profit",
          text: "الربح الشهري",
          align: "center",
        },
        {
          value: "total_profit",
          text: "اجمالى الربح",
          align: "center",
        },
        {
          value: "remining_month",
          text: "الشهور المتبقية",
          align: "center",
        },
      ],
      expired_headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنشاء",
          align: "center",
        },
        {
          value: "end_date",
          text: "تاريخ الأنتهاء",
          align: "center",
        },
        {
          value: "amount",
          text: "مبلغ الأستثمار",
          align: "center",
        },
        {
          value: "percent",
          text: "النسبة",
          align: "center",
        },
        {
          value: "month_profit",
          text: "الربح الشهري",
          align: "center",
        },
        {
          value: "total_profit",
          text: "اجمالى الربح",
          align: "center",
        },
      ],

      canceld_headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنشاء",
          align: "center",
        },
        {
          value: "end_date",
          text: "تاريخ الأنتهاء",
          align: "center",
        },
        {
          value: "canceled_date",
          text: "تاريخ الألغاء",
          align: "center",
        },
        {
          value: "amount",
          text: "مبلغ الأستثمار",
          align: "center",
        },
        {
          value: "percent",
          text: "النسبة",
          align: "center",
        },
        {
          value: "actions",
          text: "سبب الألغاء",
          align: "center",
        },
      ],
    };
  },
  methods: {
    open_dialog(number, reason) {
      this.number = number;
      this.reason = reason;
      this.dialog = true;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
