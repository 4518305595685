<template>
  <v-container>
    <v-row class="mt-0 mt-sm-10" v-if="!data_loaded">
      <v-col cols="12" sm="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" sm="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" sm="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row class="mt-0 mt-sm-10" v-if="data_loaded">
      <v-col cols="12" sm="4">
        <v-card color="info">
          <v-card-text class="align-center">
            <div class="d-flex justify-space-between mb-2">
              <h3 class="text-center white--text font-weight-medium">
                الرصيد الحالى
              </h3>
              <p class="text-center number">
                <span class="float-start me-1">USDT</span>
                {{
                  Intl.NumberFormat("en-US").format(
                    $store.state.balance.toFixed(1)
                  )
                }}
              </p>
            </div>
            <v-divider></v-divider>
            <v-btn to="/send_balance" class="mt-3" small
              >ارسال رصيد <v-icon small class="ms-3">send</v-icon></v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card color="blue-grey darken-1">
          <v-card-text class="align-center">
            <div class="d-flex justify-space-between mb-2">
              <h3 class="text-center white--text font-weight-medium">
                رصيد الأستثمار
              </h3>
              <p class="text-center number">
                <span class="float-start me-1">USDT</span>
                {{
                  Intl.NumberFormat("en-US").format(
                    $store.state.certificates_balance.toFixed(1)
                  )
                }}
              </p>
            </div>
            <v-divider></v-divider>
            <v-btn class="mt-3" to="/certificate" small
              >اضافة شهادة <v-icon class="ms-2" small>add</v-icon></v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card height="103" color="orange">
          <v-card-text class="align-center">
            <div class="d-flex justify-space-between mb-2">
              <h3 class="text-center white--text font-weight-medium">
                عدد الشهادات
              </h3>
              <p class="text-center number">{{ certificates_num }}</p>
            </div>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <Table :data_loaded="data_loaded" :certificates="certificates"></Table>
  </v-container>
</template>

<script>
import Table from "@/components/dashboard/table";
export default {
  name: "Dashboard",
  data() {
    return {
      data_loaded: false,
      certificates: {
        available: [],
        expired: [],
        canceld: [],
      },
    };
  },
  components: {
    Table,
  },
  computed: {
    certificates_num() {
      return (
        this.certificates.expired.length +
        this.certificates.available.length +
        this.certificates.canceld.length
      );
    },
  },
  methods: {
    get_certificates() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "certificates",
            method: "get",
          },
        })
        .then((res) => {
          this.certificates = res.data.data;
          this.data_loaded = true;
          // console.log(this.certificates);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    },
  },
  mounted() {
    this.get_certificates();
  },
};
</script>

<style lag="scss" scoped>
.number {
  font-size: 20px;
  color: #fff;
}
</style>
